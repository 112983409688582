
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        














































.listing__list {
  @extend %list-nostyle;
}

.listing__list__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;
  line-height: 1.25;

  &:last-child {
    margin-bottom: 0;
  }
}

.listing__list__item__text {
  ::v-deep p:first-child {
    margin-top: 0;
  }
}

.listing__list__item__icon {
  flex-shrink: 0;
  fill: $c-green-light;
  width: 2rem;
  height: 2rem;
  margin-right: $spacing;

  &.is-gray {
    fill: $c-blue-light-pale;
  }

  &.shared-icons-crossrounded {
    padding: 0.3rem;
  }

  @include mq('s') {
    width: 2.6rem;
    height: 2.6rem;
  }
}
