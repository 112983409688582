
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        




















































































.options,
[class*='options--'] {
  position: relative;
}

.options__title {
  margin-top: 0;
  margin-bottom: 2.5rem;
}

.options__list {
  @extend %list-nostyle;
}

.options__list__item {
  margin-bottom: 1rem;
}

.options__list__item__box {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  box-shadow: 0 6px 40px rgb(0 0 0 / 15%);
  background-color: $c-light;
  border-radius: 0.75rem;
  transform: scale(1);
  transition: transform 0.25s ease;

  .options__list__item:hover & {
    transform: scale(1.025);
  }

  @include mq('s') {
    grid-template-columns: auto 12rem;
  }
}

.box-inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.no-picture {
    padding-left: 1.5rem;
  }
}

.box__text {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1rem;
}

.box__text__title {
  margin: 0 0 0.25rem;
}

.box__text__subtitle {
  margin: 0;

  ::v-deep p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.box__picture {
  display: flex;
  align-items: center;
  position: relative;
  width: 10rem;
  overflow: hidden;
  padding: 1.5rem;
  line-height: 0;
  border-radius: 0.75rem;
}

.box__picture__img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  aspect-ratio: 1;
}

.box__price {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0;
  background-color: $c-bunting;
  color: $c-light;
  font-family: $ff-alt;
  border-radius: 0 0 0.75rem 0.75rem;

  @include mq('s') {
    padding: 0;
    border-radius: 0 0.75rem 0.75rem 0;
  }
}

.box__price__amount {
  font-size: 3.2rem;
  font-weight: 900;

  .is-free & {
    font-size: 2.6rem;
    font-weight: 700;
  }
}

.box__price__info {
  padding-left: 0.25rem;
  display: flex;
  flex-direction: column;

  > span {
    line-height: 1.25;
    font-size: 1.3rem;
  }
}
