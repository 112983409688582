
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        

























































































.hardware-hero {
  --picture-height: 25rem;

  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -6rem; // padding-top of panel default
  padding: $spacing $spacing * 2.5;
  color: $c-light;
  background-color: $c-promo;

  @include mq(m) {
    padding: $spacing * 2.5 $spacing * 3;
  }

  @include mq(l) {
    --picture-height: 50rem;

    max-height: calc(var(--picture-height) - 10rem);
  }
}

.hardware-hero__content {
  @extend %fw-medium;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 50% 0 0;
  align-self: center;
  font-family: $ff-alt;
  font-size: 1.5rem;
}

.hardware-hero__content__logo {
  position: relative;
  margin: 0 0 $spacing;

  img {
    max-height: 5rem;
  }
}

.hardware-hero__content__title {
  margin: 0;
}

.hardware-hero__content__label {
  margin-top: $spacing * 0.75;
}

.hardware-hero__content__description {
  @extend %fw-bold;

  margin-top: $spacing;
  font-family: $ff-alt;

  ::v-deep {
    @include owl; // remove 'outside' p margins
  }
}

.hardware-hero__picture {
  position: relative;
  z-index: 1;
  flex: 50% 0 0;
  margin: 0;
  height: var(--picture-height);

  img {
    @include image-fit(contain, right bottom);
  }
}

.hardware-hero__background-outer {
  @include get-all-space;

  z-index: -1;
  overflow: hidden;
}

.hardware-hero__speedline {
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 50%;
  max-height: 27rem;
  fill: $c-pink-medium;

  ::v-deep {
    path {
      transform: translateY(80%);
    }
  }
}
