
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        



























.section,
[class*='section--'] {
  position: relative;
}

.section__icon {
  margin-bottom: 1rem;
  width: 6rem;
  height: auto;
  max-height: 4.4rem;
  fill: $c-pink-medium;

  @include mq('s') {
    width: 8rem;
  }
}

.section__title {
  margin-top: 0;
  margin-bottom: 2.5rem;
}

.section__wysiwyg {
  ::v-deep {
    p {
      @include mq('s') {
        margin: 2.5rem 0;
      }
    }
  }
}
