
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        




























.section-icons,
[class*='section-icons--'] {
  position: relative;
}

.section-icons__icon {
  margin-bottom: 1rem;
  width: 6rem;
  height: auto;
  fill: $c-pink-medium;

  &.is-gray {
    fill: $c-light;
  }

  @include mq('s') {
    width: 8rem;
  }
}

.section-icons__title {
  margin-top: 0;
  margin-bottom: 2.5rem;
}
