
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        






























































.panel-default,
[class*='panel-default--'] {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-width: 70rem;
  min-height: 100%;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background: $c-light;
}

.panel-default-inner {
  height: 100%;
}

.panel-default__section {
  // prettier-ignore
  @include fluid(margin-bottom, (xs: 30px, l: 50px));

  padding: 0 1.5rem;

  &.no-gutters {
    margin-top: -6rem;
    margin-bottom: 4rem;
    padding: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.no-panel-padding {
    margin-top: -11rem;
  }

  @include mq('s') {
    padding: 0 6rem;
  }
}

::v-deep {
  .header__title {
    margin-top: 0;
    margin-bottom: 0;
  }

  .header__headline {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 900;
  }

  .faq__title {
    display: none;
  }

  .faq-item__title {
    padding: $spacing;
  }

  .faq-item__excerpt {
    padding: 0 $spacing $spacing;

    p {
      margin: 0;
    }
  }

  .wysiwyg__content {
    width: auto;

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    table {
      margin: $spacing 0;
      text-align: left;
      border: 1px solid $c-blue-lightest;
      border-collapse: collapse;
      border-spacing: 0;

      td {
        padding: 1rem;
        border: 1px solid $c-blue-lightest;
      }
    }

    b {
      color: initial;
    }
  }

  .g-video {
    height: auto;
    margin: 0 !important;
    padding: 0 !important;

    .plyr__video-wrapper {
      aspect-ratio: 16 / 9;
    }

    .plyr__poster {
      background-size: cover;
    }
  }
}
